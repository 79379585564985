// eslint-disable-next-line no-restricted-imports
import {observe} from '@github/selector-observer'
import {onKey} from '@github-ui/onfocus'
import {validate} from './github/behaviors/html-validation'
// eslint-disable-next-line no-restricted-imports
import {on} from 'delegated-events'

onKey('keyup', '.js-password-confirm', validatePasswordConfirmation)

onKey('keyup', '.js-password-with-confirmation', () => {
  const passwordConfirmField = document.querySelector<HTMLInputElement>('.js-password-confirm')!
  if (passwordConfirmField.value !== '') {
    validatePasswordConfirmation()
  }
})

// Add suggested usernames to form
observe('.js-suggested-usernames-container', function (container) {
  const suggestions = container.querySelectorAll<HTMLInputElement>('.js-suggested-username')
  const form = document.querySelector('.js-signup-form')

  if (!form || suggestions.length === 0) {
    return
  }

  for (const suggestion of suggestions) {
    form.appendChild(suggestion)
  }
})

observe('.js-octocaptcha-parent', function (form) {
  const spinner = form.querySelector<HTMLElement>('.js-octocaptcha-spinner')!
  const success = form.querySelector<HTMLElement>('.js-octocaptcha-success')!
  const input = form.querySelector<HTMLInputElement>('.js-octocaptcha-token')!
  const formSubmit = form.querySelector<HTMLButtonElement>('.js-octocaptcha-form-submit')!
  const iframe = form.querySelector<HTMLIFrameElement>('.js-octocaptcha-frame')!
  const octocaptchaUrl = input.getAttribute('data-octocaptcha-url')
  const dataCaptchaTimeout = input.getAttribute('data-octocaptcha-timeout')
  const captchaTimeout = dataCaptchaTimeout ? parseInt(dataCaptchaTimeout) : 30000
  const dynamicallyLoadCaptcha = input.getAttribute('data-dynamically-load-captcha') === 'true'

  let loaded = false

  const showSuccess = () => {
    if (loaded) return
    loaded = true
    /* eslint-disable-next-line github/no-d-none */
    spinner.classList.add('d-none')
    /* eslint-disable-next-line github/no-d-none */
    success.classList.remove('d-none')
    formSubmit.disabled = false
    formSubmit.hidden = false
    if (dynamicallyLoadCaptcha) formSubmit.focus()
  }

  const showCaptcha = (height: number, width: number) => {
    if (loaded) return
    loaded = true
    /* eslint-disable-next-line github/no-d-none */
    spinner.classList.add('d-none')
    iframe.classList.remove('v-hidden')
    iframe.style.height = `${height}px`
    iframe.style.width = `${width}px`
    iframe.contentWindow?.postMessage({event: 'captcha-loaded-ack'}, octocaptchaUrl || '')
  }

  const showFailedToLoadSuccess = () => {
    if (loaded) return
    const hiddenInput = document.createElement('input')
    hiddenInput.type = 'hidden'
    hiddenInput.id = 'error_loading_captcha'
    hiddenInput.name = 'error_loading_captcha'
    hiddenInput.value = '1'

    form.appendChild(hiddenInput)
    input.required = false

    showSuccess()
  }

  const captchaComplete = () => {
    if ((form as HTMLFormElement).checkValidity()) {
      formSubmit.disabled = false
    }
    if (dynamicallyLoadCaptcha) {
      setTimeout(() => {
        formSubmit.click()
      }, 0)
    } else {
      formSubmit.hidden = false
    }
  }

  if (dynamicallyLoadCaptcha) {
    const loadCaptchaButton = form.querySelector<HTMLIFrameElement>('.js-octocaptcha-load-captcha')!
    const elementsToHide = form.querySelectorAll<HTMLElement>('.js-octocaptcha-hide')
    const dataFields = form.querySelectorAll<HTMLInputElement>('.js-octocaptcha-data-field')
    const src = iframe.getAttribute('data-src') || ''
    const userFields = form.querySelectorAll<HTMLInputElement>('.signup-form-fields__input')

    let triggered = false
    loadCaptchaButton.addEventListener('click', () => {
      if (triggered) return
      triggered = true

      // captchaContainer is only present when nux_signup_flow enabled
      const captchaContainer = document.getElementById('captcha-container-nux')!
      const invalidUserFields = []
      for (const f of userFields) {
        if (!f.checkValidity()) {
          invalidUserFields.push(f)
        }
      }
      if (captchaContainer && invalidUserFields.length > 0) {
        invalidUserFields[0]!.focus()
        triggered = false
      } else {
        if (captchaContainer) {
          captchaContainer.hidden = false
          setTimeout(() => {
            captchaContainer.querySelector<HTMLElement>('.js-octocaptcha-focus-target')!.focus()
          }, 0)
        }

        for (const e of elementsToHide) {
          e.hidden = true
        }
      }

      const octocaptchaData: Record<string, string> = {}
      for (const f of dataFields) {
        const name = f.getAttribute('data-octocaptcha-field-name') ?? f.name
        octocaptchaData[name] = f.value
      }

      const url = new URL(src, octocaptchaUrl || 'https://octocaptcha.com')
      const params = url.searchParams

      params.set('data', JSON.stringify(octocaptchaData))
      iframe.src = url.toString()
      setTimeout(showFailedToLoadSuccess, captchaTimeout)
    })
  } else {
    setTimeout(showFailedToLoadSuccess, captchaTimeout)
  }

  // If captcha fails to load, let the user through
  iframe.addEventListener('error', showFailedToLoadSuccess)

  window.addEventListener('message', e => {
    if (e.origin !== octocaptchaUrl) return

    const event = e.data && e.data.event

    if (event === 'captcha-loaded') {
      const height = e.data.height || 380
      const width = e.data.width || 654
      showCaptcha(height, width)
    } else if (event === 'captcha-complete') {
      input.value = e.data.sessionToken
      captchaComplete()
    } else if (event === 'captcha-suppressed') {
      showSuccess()
    }
  })
})

observe('.js-survey-answer-choice:checked', {
  add(input) {
    const answer = input.closest('.js-answer')
    if (answer) {
      const answerChoice = answer.querySelector('.js-answer-choice')
      if (answerChoice) {
        answerChoice.classList.remove('color-border-subtle', 'color-bg-default')
        answerChoice.classList.add('color-border-accent-emphasis', 'color-bg-accent')
      }
    }

    const otherRoleInput = document.querySelector(
      `.js-other-input-box[data-other-input-for=${input.getAttribute('data-question-short-text')}]`,
    )

    if (otherRoleInput instanceof HTMLElement && input.classList.contains('js-other-choice')) {
      otherRoleInput.hidden = false
    }
  },
  remove(input) {
    const answer = input.closest('.js-answer')
    if (answer) {
      const answerChoice = answer.querySelector('.js-answer-choice')
      if (answerChoice) {
        answerChoice.classList.remove('color-border-accent-emphasis', 'color-bg-accent')
        answerChoice.classList.add('color-border-subtle', 'color-bg-default')
      }
    }

    const otherRoleInput = document.querySelector(
      `.js-other-input-box[data-other-input-for=${input.getAttribute('data-question-short-text')}]`,
    )

    if (otherRoleInput instanceof HTMLElement && input.classList.contains('js-other-choice')) {
      otherRoleInput.hidden = true
    }
  },
})

observe('.js-allow-multiple:checked', {
  constructor: HTMLInputElement,
  add(input) {
    const maximumAllowedAnswers = parseInt(input.getAttribute('data-max-choices') || '')

    const parentQuestion = input.closest<HTMLElement>('.js-question')!
    const allAnswers = parentQuestion.querySelectorAll<HTMLInputElement>('.js-allow-multiple')
    const selectedAnswerCount = Array.from(allAnswers).filter(el => el.checked === true).length

    // Disable a question's unchecked answers when three or more are selected
    if (selectedAnswerCount >= maximumAllowedAnswers) {
      for (const answer of allAnswers) {
        if (answer.checked === false) {
          answer.disabled = true
        }
      }
    }
  },
  remove(input) {
    const parentQuestion = input.closest<HTMLElement>('.js-question')!

    for (const answer of parentQuestion.querySelectorAll<HTMLInputElement>('.js-allow-multiple')) {
      answer.disabled = false
    }
  },
})

function validatePasswordConfirmation() {
  const passwordField = document.querySelector<HTMLInputElement>('.js-password-with-confirmation')!
  const passwordConfirmField = document.querySelector<HTMLInputElement>('.js-password-confirm')!

  if (passwordConfirmField.value !== passwordField.value) {
    showPasswordValidationError(passwordConfirmField)
  } else {
    hidePasswordValidationError(passwordConfirmField)
  }
}

function showPasswordValidationError(passwordConfirmField: HTMLInputElement) {
  const passwordConfirmFormGroup = passwordConfirmField.closest<HTMLElement>('.js-form-group')!
  passwordConfirmFormGroup.classList.add('errored')

  const validityMessage = passwordConfirmField.getAttribute('data-validity-message')
  if (validityMessage) {
    passwordConfirmField.setCustomValidity(validityMessage)
    const form = passwordConfirmField.closest<HTMLFormElement>('form')!
    validate(form)
  }

  const errorNode = passwordConfirmFormGroup.querySelector('.error')
  const errorText = passwordConfirmField.getAttribute('data-error-message')
  if (!errorNode && errorText) {
    const newErrorNode = document.createElement('dd')
    newErrorNode.classList.add('error')
    newErrorNode.textContent = errorText
    passwordConfirmFormGroup.appendChild(newErrorNode)
  }
}

function hidePasswordValidationError(passwordConfirmField: HTMLInputElement) {
  const passwordConfirmFormGroup = passwordConfirmField.closest<HTMLElement>('.js-form-group')!
  passwordConfirmField.setCustomValidity('')
  passwordConfirmFormGroup.classList.remove('errored')
  const form = passwordConfirmField.closest<HTMLFormElement>('form')!
  validate(form)

  const errorNode = passwordConfirmFormGroup.querySelector('.error')
  if (errorNode) {
    passwordConfirmFormGroup.removeChild(errorNode)
  }
}

// Keep the row selection in sync with the checkbox values
observe('.js-plan-choice:checked', {
  add(el) {
    const row = el.closest('.plan-row')
    if (row) {
      row.classList.add('selected')
    }
  },
  remove(el) {
    const row = el.closest('.plan-row')
    if (row) {
      row.classList.remove('selected')
    }
  },
})

on('auto-check-success', '.js-signup-input', async function (event) {
  const input = event.currentTarget as HTMLInputElement
  input.setAttribute('aria-invalid', 'false')
})

on('auto-check-error', '.js-signup-input', async function (event) {
  const input = event.currentTarget as HTMLInputElement
  input.setAttribute('aria-invalid', 'true')
})

onKey('keyup', '.js-signup-input', function (event) {
  const input = event.currentTarget as HTMLInputElement
  if (input.value.length === 0) {
    input.removeAttribute('aria-invalid')
  }
})

// To animate closing of detail element
on('click', '.signups-rebrand__details', function (e) {
  const target = e.target as HTMLElement
  const details = target.closest('details')

  if (details?.hasAttribute('open')) {
    e.preventDefault()
    details.classList.add('closing')
  }
})

on('animationend', '.signups-rebrand__details', function (e) {
  const target = e.target as HTMLElement
  const details = target.closest('details')

  if (e.animationName === 'close') {
    details?.removeAttribute('open')
    details?.classList.remove('closing')
  }
})
